import {bindable, customElement, inject} from 'aurelia-framework';
import {Client} from '../api/client';
import {DialogService} from "aurelia-dialog";
import * as _ from "lodash";

@customElement('sio-file-input')
@inject(
    Client,
    DialogService
)
export class FileInput {
    defaults = {
        sizeLimit: 10000000000,
        multiple: false,
        allowedExtensions: null,
    };

    @bindable config;
    @bindable({defaultBindingMode: 2}) value;

    @bindable uploaded;

    constructor(
        client,
        dialogService
    ) {
        this.client = client;
        this.dialogService = dialogService;
    }

    bind() {
        this._config = Object.assign({}, this.defaults, this.config);

        this.loadFiles();
    }

    async onUploadComplete(e) {

        console.debug('UPLOAD COMPLETE', e);

        if (!e.detail.uploadedFileId || e.detail.success == false) {
            //Todo handle upload error
            return;
        }

        let newFile = {
            'modelId': 'file/file',
            'id': e.detail.uploadedFileId
        };

        if (this._config.multiple) {
            if (this.value == null) {
                this.value = [];
            }

            this.value.push(newFile);
        } else {
            this.value = newFile;
        }

        await this.loadFiles();

        if (this.uploaded) {
            this.uploaded(
                _.find(this.persistedFiles, file => file.id == newFile.id)
            );
        }

        if (this._config.dontKeepFiles) {
            this.persistedFiles = [];
        }
    }

    loadFiles() {

        if (this.value == null || (_.isArray(this.value) && this.value.length == 0)) {
            //Reset files
            return;
        }

        let identifiers = [];

        if (_.isArray(this.value)) {
            for (let modelValue of this.value) {
                identifiers.push(modelValue.id);
            }
        } else {
            identifiers = [this.value.id];
        }

        return this.client.get('file/file?' + $.param({
                conditions: {
                    id: {
                        $in: identifiers
                    }
                }
            })).then(value => {

            //TODO order and validate

            this.persistedFiles = value.items;

            console.debug('LOADED VALUE', value);
        });
    }

    removeFile(index, identifier)
    {
        if (index > -1) {
            this.persistedFiles.splice(index, 1);
        }

        if (this._config.multiple) {

            let index = -1;

            for (let i = 0; i < this.value.length; i++) {
                let value = this.value[i];

                if (value.id === identifier) {
                    index = i;
                    break;
                }
            }

            if (index > -1) {
                this.value.splice(index, 1);
            }

        } else {
            this.value = null;
        }
    }

    publicDownloadUrl(file)
    {
        if (file.previewUrl) {
            return file.previewUrl.replace('$width', '-').replace('$height', '-');
        }

        return file.downloadUrl;
    }
}
