import {customElement, bindable, computedFrom, inject} from 'aurelia-framework';
import {DialogService} from "aurelia-dialog";

import "./thumbnail.less";

@customElement('sio-thumbnail')
@inject(
    DialogService
)
export class Thumbnail {
    @bindable file;
    @bindable options;

    constructor(
        dialogService
    ) {
        this.dialogService = dialogService;
    }

    getMediaType(mimetype)
    {
        if (!mimetype) {
            return '';
        }

        return mimetype.substr(0, mimetype.indexOf('/'));
    }

    isPlayableVideo(mimetype)
    {
        return [
            'video/ogg',
            'video/webm',
            'video/mp4'
        ].indexOf(mimetype) !== -1;
    }

    previewImageStyle(options)
    {
        if (!options) {
            return '';
        }

        if (options.maxWidth) {
            return 'max-width: ' + options.maxWidth;
        }

        return '';
    }
}
